import React, { useState, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import CartContext from "../contexts/CartContext"

import styles from "./accessoiresItems.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"
import SwiperSlider from "../components/swiperSlider"

const Vetements = () => {
  const [activeSizeOption1, setActiveSizeOption1] = useState("M")
  const [activeSizeOption2, setActiveSizeOption2] = useState("M")
  const [activeSizeOption3, setActiveSizeOption3] = useState("M")
  const { addItem } = useContext(CartContext)
  const data = useStaticQuery(graphql`
    query {
      veste1: allStrapiVeste(filter: { titre: { eq: "Veste Gore Noire" } }) {
        nodes {
          images {
            url
          }
        }
      }
      vetement2: file(
        relativePath: { eq: "images/accessoires/vetements/vetement2.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vetement3: file(
        relativePath: { eq: "images/accessoires/vetements/vetement3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="vêtements pour vélos électriques"
        description="Sélection des vêtements pour vélos électriques"
      />
      <main className={styles.main}>
        <h1>Vêtements</h1>
        <Link to="/accessoires" className={styles.back}>
          <span>&lt;&lt; accessoires</span>
        </Link>
        <section className={styles.container}>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Veste Gore Tex</h6>
              <p className={styles.price}>119,00 €</p>
            </section>
            <section className={styles.frame}>
              <SwiperSlider data={data.veste1} />
            </section>
            <section className={styles.quantityOptionsContainer}>
              <div className={styles.selectionShape}></div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("XS")}
                onKeyDown={e => e.key === 1 ?? setActiveSizeOption1("XS")}
                className={
                  activeSizeOption1 !== "XS"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XS
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("S")}
                onKeyDown={e => e.key === 2 ?? setActiveSizeOption1("S")}
                className={
                  activeSizeOption1 !== "S"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                S
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("M")}
                onKeyDown={e => e.key === 3 ?? setActiveSizeOption1("M")}
                className={
                  activeSizeOption1 !== "M"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                M
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("L")}
                onKeyDown={e => e.key === 4 ?? setActiveSizeOption1("L")}
                className={
                  activeSizeOption1 !== "L"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                L
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption1("XL")}
                onKeyDown={e => e.key === 5 ?? setActiveSizeOption1("XL")}
                className={
                  activeSizeOption1 !== "XL"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XL
              </div>
            </section>
            <p className={styles.description}>
              Sobre et élégante, la veste GORE BIKE WEAR Power Gore-Tex® Active
              procure une efficace protection contre le froid, la pluie et le
              vent tout en affichant une excellente respirabilité grâce à la
              technologie Gore-Tex®Active, vous permettant de pédaler au sec
              quelles que soient les conditions météorologiques.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Veste Gore Tex",
                  `${activeSizeOption1}`,
                  1,
                  119,
                  "accessoires/vetements/vetement1.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Pantalon Gore Tex</h6>
              <p className={styles.price}>59,00 €</p>
            </section>
            <section className={styles.frame}>
              <Img
                className={styles.image}
                fluid={data.vetement2.childImageSharp.fluid}
                alt="..."
              />
            </section>
            <section className={styles.quantityOptionsContainer}>
              <div className={styles.selectionShape}></div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("XS")}
                onKeyDown={e => e.key === 1 ?? setActiveSizeOption2("XS")}
                className={
                  activeSizeOption2 !== "XS"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XS
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("S")}
                onKeyDown={e => e.key === 2 ?? setActiveSizeOption2("S")}
                className={
                  activeSizeOption2 !== "S"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                S
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("M")}
                onKeyDown={e => e.key === 3 ?? setActiveSizeOption2("M")}
                className={
                  activeSizeOption2 !== "M"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                M
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("L")}
                onKeyDown={e => e.key === 4 ?? setActiveSizeOption2("L")}
                className={
                  activeSizeOption2 !== "L"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                L
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption2("XL")}
                onKeyDown={e => e.key === 5 ?? setActiveSizeOption2("XL")}
                className={
                  activeSizeOption2 !== "XL"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XL
              </div>
            </section>
            <p className={styles.description}>
              Le pantalon GORE C3 GORE-TEX ACTIVE est idéal pour vos petites ou
              grandes randonnées à vélo en procurant une protection efficace
              contre la pluie et le vent dans des conditions météorologiques
              changeantes. Il bénéficie des qualités de protection et de
              respirabilité de la membrane Gore-Tex, parfait pour évacuer la
              transpiration tout en restant au chaud et au sec.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Pantalon Gore Tex",
                  `${activeSizeOption2}`,
                  1,
                  59,
                  "accessoires/vetements/vetement2.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Cape Vaude</h6>
              <p className={styles.price}>79,00 €</p>
            </section>
            <section className={styles.frame}>
              <Img
                className={styles.image}
                fluid={data.vetement3.childImageSharp.fluid}
                alt="..."
              />
            </section>
            <section className={styles.quantityOptionsContainer}>
              <div className={styles.selectionShape}></div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("XS")}
                onKeyDown={e => e.key === 1 ?? setActiveSizeOption3("XS")}
                className={
                  activeSizeOption3 !== "XS"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XS
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("S")}
                onKeyDown={e => e.key === 2 ?? setActiveSizeOption3("S")}
                className={
                  activeSizeOption3 !== "S"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                S
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("M")}
                onKeyDown={e => e.key === 3 ?? setActiveSizeOption3("M")}
                className={
                  activeSizeOption3 !== "M"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                M
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("L")}
                onKeyDown={e => e.key === 4 ?? setActiveSizeOption3("L")}
                className={
                  activeSizeOption3 !== "L"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                L
              </div>
              <div
                role="button"
                tabIndex="0"
                onClick={e => setActiveSizeOption3("XL")}
                onKeyDown={e => e.key === 5 ?? setActiveSizeOption3("XL")}
                className={
                  activeSizeOption3 !== "XL"
                    ? styles.options
                    : `${styles.options} ${styles.activeOption}`
                }
              >
                XL
              </div>
            </section>
            <p className={styles.description}>
              Le poncho Vaude Valdipino vous protégera de la pluie et du vent
              lors de vos déplacements à vélo. Ce poncho très léger s'enfile en
              un clin dès les premières gouttes. Il se compacte dans sa poche
              intégrée pour être facilement transportable. De plus, le Valdipino
              possède une finition hydrofuge écologique, Eco Finish, garantie
              sans fluocarbures, et bénéficie du label Vaude Green Shape,
              synonyme de produits fabriqués à partir de matériaux durables.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Cape Pluie Vaude",
                  `${activeSizeOption3}`,
                  1,
                  79,
                  "accessoires/vetements/vetement3.jpg"
                )
              }
              value="Acheter"
            />
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Vetements
